import React from 'react'
import Style from "./Setting.module.css"
import Tab from "./Tab/Tab"

const Setting = () => {
    return (
        <>
            <div className={Style.setting}>
                    <Tab/>
            </div>
        </>
    )
}

export default Setting