import React from "react";
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Style from '../src/Main.module.css'
import SideBar from "../src/components/SideBar/SideBar"
import Navbar from "./components/Navbar/Navbar";
import Tag from "../src/pages/Tag/tag";
import Location from "../src/pages/Location/location";
import Category from "../src/pages/Category/category";
import Staff from "../src/pages/Staff/staff";
import User from "../src/pages/User/user";
import Login from "../src/pages/Login/login"
import Dashboard from "../src/pages/Dashboard/dashboard";
import PrivateRoute from "./auth/PrivateRoute";
import Newsletter from "../src/pages/Newsletter/newsletter";
import StaffRegistrationForm from "../src/pages/Staff/StaffRegistrationForm/staffRegistrationForm"
import MailPreviewPage from "./pages/MailPreviewPage/MailPreviewPage";
import AdModule from "./pages/Ad Module/AdModule"
import Setting from "./pages/Setting/Setting";
import Mobile from "./components/SideBarMenu/Mobile"
import ForgotPasswordChangeMail from "./pages/ForgotPassword/Forgotpassword";
import UserRegistrationForm from "./pages/User/userRegistrationForm/UserRegistrationForm";
import Form from "./components/Form/Form"
import Event_tracking from "./pages/Tracking/Event_tracking";
import Ad_tracking from "./pages/Tracking/Ad_tracking";
import GmailPageIndex from "./pages/GmailPage";
import History from "./pages/History/History"
import ExploreMoreClicks from "./pages/Tracking/Expolore_more_clicks";
// import 'antd/dist/reset.css';
const Main = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/staff-registration-form" exact component={StaffRegistrationForm} />
                <Route path="/staff-forgot-password/:emails/:tokens" exact component={ForgotPasswordChangeMail} />
                <Route path="/user-registration-form" exact component={UserRegistrationForm} />
                <Route path="/event-redirecting/:event_details/:user_who_clicked_event_link/:news_letter_tracking_id" exact component={Event_tracking} />
                <Route path="/ad-redirecting/:ad_details/:user_who_clicked_ad_link/:news_letter_tracking_id" exact component={Ad_tracking} />
                <Route path="/Latest_Weekly_Newsletter/:template/:id/:cityId/:newsletterId/:categoryId" exact component={GmailPageIndex} />
                <Route path="/explore-more-clicks/:template/:id/:cityId/:newsletterId/:categoryId" exact component={ExploreMoreClicks} />
                {/* <Route path="/*" component={Login} /> */}
                <div className={Style.main}>
                    <SideBar />
                    <div className={Style.mainPage}>
                        <Mobile />
                        <Navbar />
                        <div className={Style.mainPage_content}>
                            <PrivateRoute path="/newsletter" component={Newsletter} />
                            <PrivateRoute path="/admodule" component={AdModule} />
                            <PrivateRoute path="/tag" component={Tag} />
                            <PrivateRoute path="/dashboard" component={Dashboard} />
                            <PrivateRoute path="/location" component={Location} />
                            <PrivateRoute path="/category" component={Category} />
                            <PrivateRoute path="/staff" component={Staff} />
                            <PrivateRoute path="/user" component={User} />
                            <PrivateRoute path="/form" component={Form} />
                            <PrivateRoute path="/history" component={History} />
                            <PrivateRoute path="/setting" component={Setting} />
                            <PrivateRoute path="/send-mail-preview/:cityId" component={MailPreviewPage} />
                        </div>
                    </div>
                </div>
            </Switch>
        </BrowserRouter>
    )
}

export default Main;