import Button from "@mui/material/Button";
import Style from "./filter.module.css";
import Divider from "@mui/material/Divider";

import Menu from "@mui/material/Menu";
import FilterListIcon from "@mui/icons-material/FilterList";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getReferencesnamesForFilter } from "../../controllers/user";
import TextField from "@mui/material/TextField";

const FilterUser = ({
  filterCategory,
  filterCity,
  setFilteruser,
  filterUser,
  setFilterCategory,
  setFilterCity,
  Locations,
  Categorys,
  filterReferenceName,
  setFilterReferenceName,
  filterDate,
  setFilterDate
}) => {
  const [filterPopOpen, setFilterPopUpOpen] = useState(null);

  const open = Boolean(filterPopOpen);

  const handleToggleFilter = (event) => {
    setFilterPopUpOpen(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setFilterPopUpOpen(null);
  };

  const [referenceNames, setReferenceNames] = useState([]);

  useEffect(() => {
    getReferencesnamesForFilter().then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setReferenceNames(res.data);
      }
    });
  }, []);

  const menuStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    padding: "1rem",
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,

      width: "max-content",
      maxWidth: "600px",
      minHeight: "400px",
      minWidth: "360px",
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleCityChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFilterCity(selectedValues);
  };

  const cityOptions = Locations?.map((data) => ({
    value: data?._id,
    label: data?.locationName,
  }));

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    if (checked) {
      setFilterCategory((prevCategory) => [...prevCategory, value]);
    } else {
      setFilterCategory((prevCategory) =>
        prevCategory.filter((category) => category !== value)
      );
    }
  };
  const handleCheckboxChangeCity = (event) => {
    const { name, value, checked } = event.target;

    if (checked) {
      setFilterCity((prevCategory) => [...prevCategory, value]);
    } else {
      setFilterCity((prevCategory) =>
        prevCategory.filter((category) => category !== value)
      );
    }
  };

  const handleCheckboxReferenceNameChange = (event) => {
    const { name, value, checked } = event.target;

    if (value === "N/A") {
      if (checked) {
        setFilterReferenceName((prevNames) => [...prevNames, null, ""]);
      } else {
        setFilterReferenceName((prevNames) =>
          prevNames.filter((prevName) => prevName !== "" && prevName !== null)
        );
      }
    } else {
      if (checked) {
        setFilterReferenceName((prevNames) => [...prevNames, value]);
      } else {
        setFilterReferenceName((prevNames) =>
          prevNames.filter((prevName) => prevName !== value)
        );
      }
    }
  };

  const [showcountcategory, setshowcountcategory] = useState(3);
  const [showcountCity, setshowcountCity] = useState(3);

  const handleCategoryChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setFilterCategory(selectedValues);
  };

  const categoryOptions = Categorys?.map((data) => ({
    value: data?._id,
    label: data?.categoryName,
  }));

  const handleReferenceNameChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setFilterReferenceName(selectedValues);
  };

  const referenceNameOptions = referenceNames?.map((data) => ({
    value: data,
    label: data,
  }));

  return (
    <>
      <div>
        <Button
          className={`${Style.filterBtn} drag`}
          sx={{ width: "max-content", height: "max-content" }}
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          disableElevation
          onClick={handleToggleFilter}
          endIcon={<FilterListIcon />}
        >
          Filter
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={filterPopOpen}
          open={open}
          transitionDuration={0}
          onClose={handleCloseFilter}
        >
          <div style={menuStyle}>
            <p style={{ fontSize: "20px" }}>Filters</p>
            <Divider></Divider>
            <p style={{ fontSize: "18px" }}>Select Category</p>
            <Select
              placeholder={" Select Category"}
              name="category"
              value={categoryOptions?.filter((option) =>
                filterCategory?.includes(option.value)
              )}
              options={categoryOptions}
              isMulti
              onChange={handleCategoryChange}
            />

            {/*
          <p style={{  fontSize: "18px" }}>
            Select Category
          </p> 
           <div className={Style.category_grid}>
            {Categorys &&
              Categorys.map((data, index) => (
                <label>
                  <MenuItem>
                    <label
                      style={{ display: "flex", gap: "5px", cursor: "pointer" }}
                    >
                      <input
                        name="category"
                        type="checkbox"
                        value={data._id}
                        checked={filterCategory.includes(data._id)}
                        onChange={handleCheckboxChange}
                      />
                      {data.categoryName}
                    </label>
                  </MenuItem>
                </label>
              ))}
          </div> */}
            {/* <div>
                        {
                            showcountcategory === Categorys.length ?
                                <p onClick={() => setshowcountcategory(3)} style={{ padding: "7px 0 10px 15px", cursor: "pointer", color: "rgba(123, 163, 223, 1)" }}>View Less</p>
                                : <p onClick={() => setshowcountcategory(Categorys.length)} style={{ padding: "7px 0 10px 15px", cursor: "pointer", color: "rgba(123, 163, 223, 1)" }}>View All..</p>
                        }
                    </div> */}
            {/* <Divider /> */}
            <p style={{ fontSize: "18px" }}>Select City</p>
            <Select
              placeholder={" Select City"}
              name="city"
              value={cityOptions?.filter((option) =>
                filterCity?.includes(option.value)
              )}
              options={cityOptions}
              isMulti
              onChange={handleCityChange}
            />

            {/* <p style={{  fontSize: "18px" }}>
            Select City
          </p>

          {Locations &&
            Locations.slice(0, showcountCity).map((data, index) => (
              <label>
                <MenuItem>
                  <label
                    style={{ display: "flex", gap: "5px", cursor: "pointer" }}
                  >
                    <input
                      name="location"
                      type="checkbox"
                      value={data._id}
                      checked={filterCity.includes(data._id)}
                      onChange={handleCheckboxChangeCity}
                    />
                    {data.locationName}
                  </label>
                </MenuItem>
              </label>
            ))}
          <div>
            {showcountCity <= Locations.length && (
              <>
                {showcountCity === Locations.length ? (
                  <p
                    onClick={() => setshowcountCity(3)}
                    style={{
                      padding: "7px 0 10px 15px",
                      cursor: "pointer",
                      color: "rgba(123, 163, 223, 1)",
                    }}
                  >
                    View Less
                  </p>
                ) : (
                  <p
                    onClick={() => setshowcountCity(Locations.length)}
                    style={{
                      padding: "7px 0 10px 15px",
                      cursor: "pointer",
                      color: "rgba(123, 163, 223, 1)",
                    }}
                  >
                    View All..
                  </p>
                )}
              </>
            )}
          </div> */}
            <p style={{ fontSize: "18px" }}>Select Reference Name</p>

            <Select
              placeholder={"Select Reference Name"}
              name="city"
              value={referenceNameOptions?.filter((option) =>
                filterReferenceName?.includes(option.value)
              )}
              options={referenceNameOptions}
              isMulti
              onChange={handleReferenceNameChange}
            />


     <p style={{ fontSize: "18px" }}>Registration Date</p>

            <TextField
              className={Style.rightSlide_formInput_input}
              value={filterDate?.from}
              type="date"
              id="from"
              name="from"
              placeholder="dssdsd"
              variant="outlined"
              label="From"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setFilterDate({
                  ...filterDate,
                  from: e.target.value,
                  to: "",
                });
              }}
            />

            <TextField
              className={Style.rightSlide_formInput_input}
              value={filterDate?.to}
              label="To"
              type="date"
              id="outlined-basic-7"
              name="to"
              variant="outlined"
              onChange={(e) =>
                setFilterDate({ ...filterDate, to: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
              {...(filterDate.from && {
                inputProps: { min: filterDate.from },
              })}
            />

            {/* <p style={{ padding: "15px 0px 5px 15px", fontSize: "18px" }}>
            Select Reference Name
          </p>

          <div className={Style.category_grid}>
            {referenceNames &&
              referenceNames.map((data, index) => (
                <label>
                  <MenuItem>
                    <label
                      style={{ display: "flex", gap: "5px", cursor: "pointer" }}
                    >
                      <input
                        name="referenceNames"
                        type="checkbox"
                        value={data}
                        checked={
                          data === "N/A"
                            ? filterReferenceName.includes("") ||
                              filterReferenceName.includes(null)
                            : filterReferenceName.includes(data)
                        }
                        onChange={handleCheckboxReferenceNameChange}
                      />
                      {data}
                    </label>
                  </MenuItem>
                </label>
              ))}
          </div> */}
            <div>
              {" "}
              <Button
                className="addBtn"
                // sx={{ margin: "10px 0 10px 15px" }}
                variant="contained"
                onClick={(e) => {
                  setFilteruser(!filterUser);
                  handleCloseFilter();
                }}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </StyledMenu>
      </div>
    </>
  );
};

export default FilterUser;
