const url = process.env.REACT_APP_API_URL




export const createUser = (id, data, token) => {
    return (
        fetch(`${url}/user/admincreate?staff=${id}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}


export const listUser = async (id, filter, token, signal) => {
    try {
        const response = await fetch(`${url}/user/list?staff=${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            signal: signal,
            body: JSON.stringify(filter)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}

export const downloadUser = async (id, filter, token, signal) => {
    try {
        const response = await fetch(`${url}/user/download-users?staff=${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            signal: signal,
            body: JSON.stringify(filter)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}




export const deletUser = async (id, user_id, token) => {
    try {
        const response = await fetch(`${url}/user/delete?id=${user_id}&staff=${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        const data = await response.json()
        return data
    } catch (error) {
        return error
    }
}

export const updateUser = async (user_id, id, userData, token) => {
    try {
        const response = await fetch(`${url}/user/update?id=${user_id}&staff=${id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(userData)
        })
        const data = await response.json()
        return data
    } catch (error) {
        return error
    }
}









export const createUserWeekendoWebsite = (data) => {
    return (
        fetch(`${url}/user/create`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
            // body: data
        })
            .then(response => {
                return response.json();
            })
            .catch(err => {
                console.log(err)
            })
    )
}



export const getReferencesnamesForFilter = (data) => {
    return fetch(`${url}/user/fetch-reference-names`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
        // body: data
    })
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
            console.log(err);
        });
};
