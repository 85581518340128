import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { createUser, deletUser, listUser, updateUser } from '../../../controllers/user'
import { isAuthenticated } from '../../../auth';
import { listCategory } from '../../../controllers/category';
import { listLocation } from '../../../controllers/location';
import { listTag } from '../../../controllers/tag';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function RightSlide({ locationAllData, userAllData, categoryAllData, tagAllData, CheckValue, itemId, refersh, setRefresh, rightDrawerOpen = false,onClose }) {

    const { token, id, permission } = isAuthenticated();

    const [state, setState] = useState({
        right: CheckValue === 2 ? true : false,
    });
    // const [allUsers, setAllUsers] = useState(userAllData)
    // const [Categorys, setCategorys] = useState(categoryAllData)
    // const [Locations, setLocations] = useState(locationAllData)
    // const [Tags, setTags] = useState(tagAllData)


    console.log(state)

    const [edit, setEdit] = useState(false)
    const [selectedCities, setSelectedCities] = useState([]);
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        userEmail: '',
        tag: '',
        phoneNumber: '',
        gender: '',
        city: [],
        dateOfBirth: "",
        category: [],
        // category: [process.env.REACT_APP_API_COLLAB_ID],
        jobType: '',
        companyName: '',
        referred_by: ""
    })
    const [tagData, settagData] = useState()
    const [click, setClick] = useState(false)

    const [filterCity, setFilterCity] = useState([])
    const [filterCategory, setFilterCategory] = useState([])

    const [gender, setGender] = useState('');

    // console.log(allUsers)

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        onClose() 
        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (CheckValue === 2) {
            handleEdit(itemId)
        }
    }, [CheckValue])

    const handleEdit = (id) => {
        setEdit(true);
        console.log(id);
        const data = userAllData?.filter((data) => data._id === id);
        const gender = data[0]?.gender
        const selectedCities = data[0]?.city.map((item) => item._id);
        const x = {
            value: data[0]?.tag._id,
            label: data[0]?.tag.tagName
        }
        settagData(x)
        setGender(gender);
        setSelectedCities(selectedCities);
        setUserData({
            ...userData,
            firstName: data[0]?.name.firstName,
            lastName: data[0]?.name.lastName,
            phoneNumber: data[0]?.phoneNumber,
            tag: data[0]?.tag._id,
            userEmail: data[0]?.userEmail,
            jobType: data[0]?.jobProfile.jobType,
            companyName: data[0]?.jobProfile.companyName,
            dateOfBirth: data[0]?.dateOfBirth.split('T')[0],
            category: data[0]?.category.map((item) => item._id),
            city: selectedCities,
            gender: gender,
            referred_by: data[0]?.referred_by
        });
    };


    // Form 







    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setUserData({ ...userData, gender: event.target.value })
    };

    const handleCityChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedCities(selectedValues);
        setUserData({
            ...userData,
            city: selectedValues
        })
    };

    const cityOptions = locationAllData?.map((data) => ({
        value: data?._id,
        label: data?.locationName,
    }));

    const handleValue = (event) => {
        const { name, value } = event.target;
        const minBirthDate = new Date();
        minBirthDate.setFullYear(minBirthDate.getFullYear() - 18);
        const minBirthDateString = minBirthDate.toISOString().split('T')[0];

        if (event.target.name === 'dateOfBirth' && event.target.value > minBirthDateString) {
            return;
        }
        setUserData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));

    };

    // console.log(userData);

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        if (checked) {
            setUserData((prevFormData) => ({
                ...prevFormData,
                [name]: [...prevFormData[name], value]
            }));
        } else {
            setUserData((prevFormData) => ({
                ...prevFormData,
                [name]: prevFormData[name].filter((item) => item !== value)
            }));
        }
    };

    const [emptyFieldError, setEmptyFieldError] = useState()
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
        return emailRegex.test(email);
    };
    const handleFormSubmit = (event) => {
        event.preventDefault();
        // console.log(userData);
        if (
            userData.firstName === '' ||
            userData.lastName === '' ||
            userData.userEmail === '' ||
            userData.tag === '' ||
            userData.phoneNumber === '' ||
            userData.gender === '' ||
            userData.city.length === 0 ||
            userData.dateOfBirth === ""
        ) {
            setEmptyFieldError("All field are required")
            return
        }

        if (userData.category.length < 5) {
            setEmptyFieldError("Select atleast 5 categories")
            return
        }
        if (!validateEmail(userData.userEmail)) {
            setEmptyFieldError('Please enter a valid email address.');
            return
        }
        if (edit === false) {
            createUser(id, userData, token)
                .then(data => {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        setRefresh(!refersh)
                        onClose() 
                        toast.success('User Added successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        // window.location.reload();
                     
                    }

                })

        }
        else {
            // console.log(userData);
            updateUser(itemId, id, userData, token).then(data => {
                if (data.error) {
                    // window.alert("Error : ", data.error)
                    toast.error(data.error, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                else {
                    setRefresh(!refersh)
                    onClose() 
                    toast.success('User Updated successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                  
                }
            })
            // onClose() 
            // setRefresh(!refersh)
        }

    }

    // console.log(userData);

    const closeDrawer = (anchor, open) => (event) => {
        if (userData.firstName === '' ||
            userData.lastName === '' ||
            userData.userEmail === '' ||
            userData.tag === '' ||
            userData.phoneNumber === '' ||
            userData.gender === '' ||
            userData.city.length === 0 ||
            userData.dateOfBirth === "" ||
            userData.category.length < 5 ||
            !validateEmail(userData.userEmail)) {
            return
        }

        setState({ ...state, [anchor]: open });
    }

    const tagoptions = tagAllData?.filter(data => data.tagName !== "staff" && data.tagName !== 'admin').map((tag) => ({
        value: tag?._id,
        label: tag?.tagName,
    }))

    const handletagSelect = (selectedOptions) => {
        // console.log(selectedOptions)
        settagData(selectedOptions)
        // setLocalityData([])
        // setMicromarketData([])
        setUserData({
            ...userData,
            tag: selectedOptions.value,
        })
    };


    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>Add User</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleFormSubmit}>
                    <div className={Style.rightSlide_formInput}>
                        <TextField className={Style.rightSlide_formInput_input} value={userData.firstName} id="outlined-basic-1" name='firstName' label="Enter First Name *" variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.lastName} id="outlined-basic-2" name='lastName' label='Enter Last Name *' variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.userEmail} id="outlined-basic-3" name='userEmail' label='Enter Email *' variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.phoneNumber} id="outlined-basic-4" name='phoneNumber' label='Enter Phone Number *' variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.jobType} id="outlined-basic-5" name='jobType' label='Enter Job Type (optional)' variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.companyName} id="outlined-basic-6" name='companyName' label='Enter Company Name (optional)' variant="outlined" onChange={handleValue} />
                        <TextField className={Style.rightSlide_formInput_input} value={userData.referred_by} id="outlined-basic-6" name='referred_by' label='Referred By (optional)' variant="outlined" onChange={handleValue} />
                    </div>
                    <div className={Style.rightSlide_formSelect}>
                        <Select
                            placeholder={"Select Tag *"}
                            name="tag"
                            options={tagoptions}
                            value={tagData}
                            onChange={handletagSelect} />

                        <Select
                            placeholder={"Select City *"}
                            name="city"
                            value={cityOptions?.filter(option => selectedCities?.includes(option.value))}
                            options={cityOptions}
                            isMulti
                            onChange={handleCityChange} />
                    </div>
                    <div className={Style.rightSlide_formRadio}>
                        <p>Select Gender *:</p>
                        <label style={{ display: 'flex', alignItems: "center", gap: '10px' }} >
                            <input style={{ height: "15px", width: "max-content" }}
                                type="radio"
                                name="gender"
                                value="male"
                                checked={gender === "male"}
                                onChange={handleGenderChange}
                            /> <p>Male</p>
                        </label>

                        <label style={{ display: 'flex', alignItems: "center", gap: '10px' }} >
                            <input style={{ height: "15px", width: "max-content" }}
                                type="radio"
                                name="gender"
                                value="female"
                                checked={gender === "female"}
                                onChange={handleGenderChange}
                            /> <p>Female</p> </label>
                    </div>


                    <div className={Style.rightSlide_formDate} >
                        <p>Date of Birth *:</p>
                        <input style={{ width: "max-content " }} value={userData.dateOfBirth} type='date' name='dateOfBirth' placeholder='Enter Date of Birth' onChange={handleValue} />
                    </div>
                    <div className={Style.rightSlide_formCategory}>
                        <p>Choose Categories *</p>
                        <div className={Style.rightSlide_formCategoryCheck}>
                            {categoryAllData && categoryAllData.map((data, index) => (
                                <>
                                    <label style={{ display: 'flex', gap: '5px' }} ><input name='category' type='checkbox' value={data._id} onChange={handleCheckboxChange} checked={userData.category.includes(data._id)} />{data.categoryName}
                                        {/* <IconButton><Tooltip title={data.description}><HelpOutlineIcon sx={{color:"rgba(91, 141, 215, 1) !important",cursor:"pointer",fontSize:"20px"}}/></Tooltip></IconButton> */}
                                    </label>
                                </>
                            ))}
                        </div>
                        {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                        <Button onClick={closeDrawer(anchor, false)} variant="contained" type='submit' className='buttonHover' style={{ backgroundColor: '#1976d2', marginTop: '10px' }} >{CheckValue === 1 ? "Add" : "Save"}</Button>
                    </div>
                </form>
            </div>
        </Box>
    );

    // console.log(click)

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {CheckValue === 1 ? <><Button onClick={toggleDrawer(anchor, true)} variant="contained" className="addBtn drag">Add User</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' style={{ display: "none" }} onClick={toggleDrawer(anchor, true)}   ><IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
            {/* <ToastContainer /> */}
        </div>
    );
}