import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GmailPageOne from './GmailPageOne/GmailPageOne'
import GmailPageTwo from "./GmailPageTwo/GmailPageTwo"
import GmailPageThree from './GmailPageThree/GmailPageThree';
import { Gmail_Page } from '../../controllers/newsletter'
import GmailPageFour from './GmailPageFour/GmailPageFour';
import { Link, Redirect, useHistory, NavLink } from "react-router-dom";
import Style from "./GmailPage.module.css"
import NewsLetterDrawer from '../../components/NewsLetterDrawer/NewsLetterDrawer';
 
const GmailPageIndex = () => {
  const { template, id, cityId, newsletterId, categoryId } = useParams()


  const [newsletterData, setNewsletterData] = useState()
  const [categoryData, setCategoryData] = useState([]);
  const [scrollToCategory,setScrollToCategory]=useState(categoryId)

  const init = async () => {
    await Gmail_Page(id, cityId, newsletterId,template).then(data => {
      if (data.error) {
        console.log(data.error);        // newsletter opened on webpage count also included in it
      }
      else {
        setNewsletterData(data)
      }
    })
  }

  useEffect(() => {
    init()
  }, [])


  useEffect(()=>{
    if (newsletterData && newsletterData?.events?.length > 0) {
      const filteredData = newsletterData.events.map((item) => {
        return {
          name: item?.category_name,
          id: item?.category_id,
        };
      });

      setCategoryData(filteredData);
    }
  },[newsletterData])

 

  useEffect(() => {
    document.title = "Weekendo Newsletter for this week. Have fun!"; // Set the initial title when the component mounts
    // You can also set the title based on the current state or props

    return () => {
      // Reset the title when the component unmounts
      document.title = "Weekendo Newsletter for this week. Have fun!";
    };
  }, []);
  return (
    <>
      {/* <div className={Style.categoryContainer}> */}
      <NewsLetterDrawer categoryData={categoryData} handleClick={(item) => setScrollToCategory(item?.id)} />
        {/* {categoryData.map((item, index) => (
          <p
            className={Style.categroyButton}
            onClick={() => setScrollToCategory(item?.id)}
            key={index}
          >
            {item?.name}
          </p>
        ))} */}
      {/* </div> */}
      {template === "1" && (
        <GmailPageOne
          id={id}
          newsletterData={newsletterData}
          newsletterId={newsletterId}
          click={true}
          categoryId={scrollToCategory}
        />
      )}
      {template === "2" && (
        <GmailPageTwo
          id={id}
          newsletterData={newsletterData}
          newsletterId={newsletterId}
          click={true}
          categoryId={scrollToCategory}
        />
      )}
      {template === "3" && (
        <GmailPageThree
          id={id}
          newsletterData={newsletterData}
          newsletterId={newsletterId}
          click={true}
          categoryId={scrollToCategory}
        />
      )}
      {template === "4" && (
        <GmailPageFour
          id={id}
          newsletterData={newsletterData}
          newsletterId={newsletterId}
          click={true}
          categoryId={scrollToCategory}
        />
      )}
    </>
  );
}

export default GmailPageIndex
