import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Style from "./DownloadUserPopup.module.css"
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button from '@mui/material/Button';
import { downloadUser } from '../../../controllers/user';
import { isAuthenticated } from '../../../auth';
import * as XLSX from 'xlsx';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 900,
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: 24,
};

const DownloadUserPopup = ({ count, open, handleClose, filters }) => {

    const { token, id } = isAuthenticated();

    const [loading, setLoading] = useState(false)
    const [range, setRange] = useState({
        from: 1,
        to: count
    })

    const [error, setError] = useState("")

    const handleChange = (e) => {
        const { name, value } = e.target
        setRange({
            ...range,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        const data = {
            ...filters,
            ...range
        }

        if (range.to < range.from) {
            setError('To cannot be less than from');
            return;
        }
        if (range.from <= 0) {
            setError('Please select from greater than 0');
            return;
        }
        if (range.to <= 0) {
            setError('Please select to greater than 0');
            return;
        }
        if (range.from > count) {
            setError('From cannot be greater than user count');
            return;
        }
        if (range.to > count) {
            setError('To cannot be greater than user count');
            return;
        }
        setLoading(true)
        await downloadUser(id, data, token)
            .then((res) => {
                if (res.error) {
                    setError(res.error)
                } else {
                    const ws = XLSX.utils.json_to_sheet(res);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    XLSX.writeFile(wb, 'Users Data.xlsx');
                    handleClose()
                }
                setLoading(false)
            })
            .catch((error) => console.error(error));

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={Style.modal}
            >
                <Box sx={style} className={Style.popUp} >

                    <div className={Style.popUpHead}><span onClick={handleClose} ><CloseOutlinedIcon sx={{ color: 'white' }} /></span><h3>Download User</h3></div>
                    <div className={Style.popUpForm} style={{ gap: "1rem" }}>
                        <div className={Style.popUpForm_mail}>
                            <p htmlFor="from">From *</p>
                            <TextField size='small' className={Style.popUpForm_input} id="outlined-basic-1" value={range.from} name='from' label="Enter from" type="number" variant="outlined" onChange={handleChange} />
                        </div>
                        <div className={Style.popUpForm_mail} style={{ margin: "0" }}>
                            <p htmlFor="from">To *</p>
                            <TextField size='small' className={Style.popUpForm_input} id="outlined-basic-1" value={range.to} name='to' label="Enter to" type="number" variant="outlined" onChange={handleChange} />
                        </div>
                        <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                            {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>}
                            <Button onClick={handleSubmit} variant="contained" className='addBtn' sx={{ width: "max-content" }} disabled={loading}>{loading ? "Downloading..." : "Download"}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default DownloadUserPopup