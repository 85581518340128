import React, { useEffect, useState } from 'react'
// import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
// import TextField from '@mui/material/TextField';
import { createCategory, deletCategory, editCategory, listCategory } from '../../../controllers/category'
import { isAuthenticated } from '../../../auth';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';



export default function RightSlide({ CheckValue, Categorys, itemId, refersh, setRefresh }) {

    const { token, id, permission } = isAuthenticated();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [description, setDescription] = useState('')
    const [edit, setEdit] = useState(false)
    const [Category, setCategory] = useState('')
    const [categoryID, setCategoryID] = useState()
    const [Check, setCheck] = useState(false)
    const [color, setcolor] = useState('')


    // for updating Category


    const handleEdit = (category_id) => {
        setEdit(true)
        const data = Categorys.filter((data) => data._id === category_id)
        setCategoryID(category_id)
        setCategory(data[0].categoryName)
        setDescription(data[0].description)
        setcolor(data[0].color)
    }

    // Toggle Drawer 

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (CheckValue === 2 && open === true) {
            handleEdit(itemId)
        }

        setState({ ...state, [anchor]: open });
        // console.log(open)
    };
    const closeDrawer = (anchor, open) => (event) => {
        if (Category === "" || description === "") {
            return
        }
        setState({ ...state, [anchor]: open });
    }




    // Captilizing first letter
    const capitalizeSentences = (sentence) => {
        let result = sentence.toLowerCase();
        result = result.replace(/(^|\.\s+)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
        return result;
    };



    const handleChange = (e) => {
        setCategory(e.target.value)
    }
    const [emptyFieldError, setEmptyFieldError] = useState()
    //  for adding Category
    const handleSubmit = (e) => {
        e.preventDefault()
        if (Category === "" || description === "") {
            setEmptyFieldError("All field are required")
            return
        }
        if (edit === false) {
            const data = {
                categoryName: Category,
                description: description,
                color: color,
                createdBy: id,
            }
            createCategory(id, data, token)
                .then(data => {
                    if (data.error) {
                        console.log("error : ", data.error);
                    } else {

                        toast.success('Category added successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
        }
        else {
            const data = {
                categoryName: Category,
                description: description,
                color: color
                // createdBy:"",
            }
            editCategory(id, data, categoryID, token).then(data => {
                if (data.error) {
                    console.log("error : ", data.error);
                } else {
                    toast.success('Category Updated successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(error => console.error(error))
        } setRefresh(!refersh)
    }

    // for listing all the Categorys









    // List For Right Slide 

    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>Add Category</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleSubmit}>
                    <div className={Style.rightSlide_formInput}>
                        <TextField className={Style.rightSlide_formInput_input} value={Category} id="outlined-basic-1" name='firstName' label='Enter Category Name *' variant="outlined" onChange={handleChange} />
                    </div>
                    <div className={Style.rightSlide_formTextarea}>
                        <Textarea
                            minRows={2}
                            placeholder="Enter Category Description *"
                            size="md"
                            variant="outlined"
                            value={description}
                            onChange={(e) => {
                                if (e.target.value.length <= 200) {
                                    setDescription(capitalizeSentences(e.target.value))
                                } else {
                                    return
                                }
                            }}
                        />
                        <span style={{ fontSize: "12px" }}>(Max 100 char)</span>
                    </div>
                    <div className={Style.rightSlide_formTextarea}>
                        <Textarea
                            minRows={2}
                            placeholder="Enter Color for category"
                            size="md"
                            variant="outlined"
                            maxLength="200" value={color}
                            onChange={(e) => setcolor(e.target.value)}
                        />
                    </div>
                    {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                    <div className={Style.rightSlide_formBtn}>
                        <Button onClick={closeDrawer(anchor, false)} variant="contained" className='addBtn'
                            type='submit'>{CheckValue === 1 ? "Add" : "Save"}</Button>
                    </div>
                </form>
            </div>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    {CheckValue === 1 ? <><Button onClick={toggleDrawer(anchor, true)} className='addBtn drag' variant="contained" >Add Category</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' onClick={toggleDrawer(anchor, true)}   ><IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
            {/* <ToastContainer /> */}
        </div>
    );
}