import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from "@mui/material";
export default function NewsLetterDrawer({ categoryData, handleClick }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: "350px", background: "#1AD5E7" }} role="presentation" onClick={toggleDrawer(false)}>
      <div style={{ display: "flex", justifyContent: "center", background: "#fff",width:"100%" }}>
        <img src="/logo192.png" width={"150px"} />
      </div>

      {/* <Divider /> */}

      <List style={{ padding: "10px" }}>
        {categoryData.map((item, index) => (
          <ListItem key={index} disablePadding style={{ borderRadius: "10px" }}>
            <ListItemButton onClick={() => handleClick(item)}>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton
        // variant="contained"
        style={{ position: "fixed", top: "2%", right: "1%", zIndex: 999, background: "#1AD5E7" }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon style={{ color: "white" }} />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
