const url = process.env.REACT_APP_API_URL


export const fetchApi= async (token,data) => {
    try {
        const response = await fetch(`${url}/dashboard-analytics/fetch`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                Authorization: `Bearer ${token}`
            },
            body:JSON.stringify(data)
        });
        const res = await response.json();
        return res;
    } catch (error) {
        console.error('Error fetching data:', error);
        return error;
    }
}
