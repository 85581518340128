import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Avatar from '@mui/material/Avatar';

import { Link, useHistory, } from "react-router-dom";
import Style from "./Mobile.module.css";
import { signout, isAuthenticated } from "../../auth";



const SidebarMenu = () => {
    const [isChecked, setIsChecked] = React.useState(false);
    const history = useHistory();

    const { name, tag } = isAuthenticated();


    const handleChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const goToSeciton = () => {
        setIsChecked(false);
    };
    const handleSignout = () => {
        signout(() => {
            history.push("/");
            // return <Redirect to="/" />;
        });
    };
    return (
        <>
            <div className={Style.mobile_menu}>
                <Link to="/" onClick={goToSeciton}>
                    <img className={Style.logo} src="\Assets\MailAsset\Logo.svg" alt="nn" />
                </Link>

                <div className={Style.right}>
                    {!isChecked && (
                        <label
                            className={Style.button}
                            style={{ width: "40px", marginLeft: "10px" }}
                        >
                            <MenuRoundedIcon />
                            <input
                                type="checkbox"
                                onChange={handleChange}
                                checked={isChecked}
                                hidden
                            />
                        </label>
                    )}
                </div>
            </div>
            <div className={`${Style.main} ${isChecked ? Style.active_Nav : Style.deactive_Nav} `}>
                <div className={Style.container}>
                    <div className="" style={{display:'flex', justifyContent:'space-between'}} >
                        <div className={Style.containerAccount}>
                            <Avatar sx={{
                                bgcolor: "#b471fa",
                                fontSize: "20px",
                                height: "35px",
                                width: "35px",
                            }} >{name?.firstName[0].toUpperCase()}</Avatar>
                            <div className={Style.containerAccount_detail}>
                                <p>{name?.firstName}{' '}{name?.lastName}</p>
                                <span style={{ display: 'flex', alignItems: 'center' }} >{tag}</span>
                            </div>
                        </div>
                        <div className="">
                            {isChecked && (
                                <label
                                    className=''
                                    style={{ width: "40px", marginLeft: "10px" }}
                                >
                                    <CloseIcon />
                                    <input
                                        type="checkbox"
                                        onChange={handleChange}
                                        checked={isChecked}
                                        hidden
                                    />
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="">
                        <Link className={Style.link} to='/dashboard' onClick={goToSeciton}>
                            Analytics
                        </Link>
                        <Link
                            className={Style.link}
                            to='/user'
                            onClick={goToSeciton}
                        >
                            Users
                        </Link>
                        <Link
                            className={Style.link}
                            to='/tag'
                            onClick={goToSeciton}
                        >
                            Tags
                        </Link>
                        <Link
                            className={Style.link}
                            to='/location'
                            onClick={goToSeciton}
                        >
                            Locations
                        </Link>
                        <Link
                            className={Style.link}
                            to='/category'
                            onClick={goToSeciton}
                        >
                            Categories
                        </Link>
                        <Link
                            className={Style.link}
                            to='/newsletter'
                            onClick={goToSeciton}
                        >
                            Newsletters
                        </Link>
                        <Link
                            className={Style.link}
                            to='/admodule'
                            onClick={goToSeciton}
                        >
                            Ad Module
                        </Link>
                        <Link
                            className={Style.link}
                            to='/staff'
                            onClick={goToSeciton}
                        >
                            Team
                        </Link>
                        <Link
                            className={Style.link}
                            to='/history'
                            onClick={goToSeciton}
                        >
                            History
                        </Link>
                        <Link
                            className={Style.link}
                            to='/setting'
                            onClick={goToSeciton}
                        >
                            Settings
                        </Link>
                        <Link
                            className={Style.link}
                            to='/#contact'
                            onClick={() => { goToSeciton(); handleSignout() }}
                        >
                            Logout
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarMenu;
