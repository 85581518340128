import { Link, Redirect, useHistory, NavLink } from "react-router-dom";
import { signout } from "../../auth";
import Style from "./SideBar.module.css"
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Toolbar } from "@mui/material";

import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInNotOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import HistoryIcon from '@mui/icons-material/History';

import { useState } from "react";

const Navbar = () => {
  const history = useHistory();

  const handleSignout = () => {
    signout(() => {
      // history.push("/");
      return <Redirect to="/" />;
    });
  };

  return (
    <>
      <div className={Style.sideBar}>
       <img src="\Assets\MailAsset\Logo.svg" alt="logo" width="70%"/>
        {/* {`{Style.link}  ${Style.active}`} */}
        {/* <div className={Style.sideBarLogo}>Weekendo</div> */}
        <div className={Style.sideBarList}>
          <button><NavLink  className={Style.link} to="/dashboard"> <span><WidgetsIcon /></span> <p>Analytics</p></NavLink></button>
          <button><NavLink className={Style.link} to="/user"> <span><PersonPinOutlinedIcon /></span><p>Users</p></NavLink></button>
          <button><NavLink className={Style.link} to="/tag"> <span><TurnedInNotOutlinedIcon /></span><p>Tags</p></NavLink></button>
          <button><NavLink className={Style.link} to="/location"> <span><EditLocationOutlinedIcon /></span><p>Locations</p></NavLink></button>
          <button><NavLink className={Style.link} to="/category"><span><CategoryOutlinedIcon /></span><p>Categories</p></NavLink></button>
          <button><NavLink className={Style.link} to="/newsletter"><span><AttachEmailOutlinedIcon /></span><p>Newsletters</p></NavLink></button>
          <button><NavLink className={Style.link} to="/admodule"><span><AttachEmailOutlinedIcon /></span><p>Ad Module</p></NavLink></button>
          <button><NavLink className={Style.link} to="/staff"><span><GroupAddOutlinedIcon /></span><p>Team</p></NavLink></button>
          <button><NavLink className={Style.link} to="/history"><span><HistoryIcon /></span><p>History</p></NavLink></button>
          <button><NavLink className={Style.link} to="/setting"><span><WidgetsIcon /></span><p>Settings</p></NavLink></button>
          <button style={{marginTop:"20px"}}><Link to="/" className={Style.logout} onClick={handleSignout}>LOGOUT</Link></button>
        </div>
      </div>
    </>

  );
};

export default Navbar;