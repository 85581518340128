import React, { useState } from 'react'
import { staffRegister } from '../../../controllers/staff';
import { useLocation } from 'react-router-dom';
import Style from "./staffRegistrationForm.module.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useHistory } from 'react-router-dom';
const StaffRegistrationForm = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const token = searchParams.get('token');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: email,
        password: "",
        cpassword: "",
        phoneNumber: "",
        token: token,
        city: ""
    })

    const handleValue = (event) => {
        const { name, value } = event.target;
        setData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (data.password !== data.cpassword) {
            window.alert("Password and confirm password do not match")
            return
        }
        // console.log(data);
       
        staffRegister(data)
            .then(data => {
                if (data.error) {
                    window.alert(data.error)
                } else {
                    window.alert("Registration Successfull")
                    history.push('/');
                }
            })
    }





    const textfieldStyle = {
        width: '300px',
        margin: '10px 0'
    }

    const btnStyle = {
        width: '300px',
        height: '50px',
        margin: '10px 0',
        backgroundColor: '#4287f5',

    }






    return (
        <>

            <form onSubmit={handleFormSubmit}>

                <div className={`${Style.admin_hireflax_form_container} flex flex-wrap flex-ac`}>

                    <div className={`${Style.admin_forimage}`}>
                        <img src="\Assets\StaffRegistrationPage\1.jpg" alt="login" />
                    </div>
                    <div className={`${Style.admin_login_form} flex flex-column flex-ac flex-jcc`}>
                        <img src="\Assets\MailAsset\Logo.svg" alt="logo" width="45%" />
                        <div className={`${Style.admin_login_heading} flex`}>
                            <span>Register Yourself</span>
                        </div>
                        <div className={`${Style.form_container} flex flex-column`}>
                            <div>

                                <div className="flex flex-column">
                                    <TextField
                                        id="standard-search"
                                        label="First Name"
                                        type="text"
                                        name='firstName' value={data.firstName} onChange={handleValue}
                                        variant="standard"
                                        style={textfieldStyle}

                                        required
                                    />
                                    <TextField
                                        id="standard-search"
                                        label="Last Name"
                                        type="text"
                                        name='lastName' value={data.lastName} onChange={handleValue}
                                        variant="standard"
                                        style={textfieldStyle}

                                        required
                                    />
                                    <TextField
                                        id="standard-search"
                                        label="Email"
                                        type="text"
                                        name='email' value={data.email} onChange={handleValue} disabled
                                        variant="standard"
                                        style={textfieldStyle}

                                        required
                                    />

                                    <TextField
                                        id="standard-search"
                                        label="Phone Number"
                                        type="number"
                                        name='phoneNumber' value={data.phoneNumber} onChange={handleValue}
                                        variant="standard"
                                        style={textfieldStyle}
                                        required
                                    />



                                    <TextField
                                        id="filled-password-input"
                                        label="Password"
                                        type="password"
                                        name='password' placeholder='Enter Password' value={data.password} onChange={handleValue}
                                        autoComplete="current-password"
                                        variant="standard"
                                        style={textfieldStyle}
                                        required
                                    />

                                    <TextField
                                        id="filled-password-input"
                                        label="Confirm Password"
                                        type='password'
                                        name='cpassword' placeholder='Confirm Password' value={data.cpassword} onChange={handleValue}
                                        autoComplete="current-password"
                                        variant="standard"
                                        style={textfieldStyle}
                                        required
                                    />


                                    <Button
                                        endIcon={<ArrowForwardRoundedIcon />}
                                        loading={loading}
                                        loadingPosition="end"
                                        variant="contained"
                                        style={btnStyle}
                                        type='submit'
                                    >
                                        Register
                                    </Button>

                                </div>

                            </div>
                            <br />

                        </div>
                    </div>
                </div>










                {/* <input type='text' name='firstName' placeholder='Enter First Name' value={data.firstName} onChange={handleValue} /><br />
                <input type='text' name='lastName' placeholder='Enter Last Name' value={data.lastName} onChange={handleValue} /><br />
                <input type='text' name='email' placeholder='Enter Email Id' value={data.email} onChange={handleValue} disabled /><br />
                <input type='password' name='password' placeholder='Enter Password' value={data.password} onChange={handleValue} /><br />
                <input type='password' name='cpassword' placeholder='Confirm Password' value={data.cpassword} onChange={handleValue} /><br />
                <input type='number' name='phoneNumber' placeholder='Enter Phone Number' value={data.phoneNumber} onChange={handleValue} /><br />
                <input type='texr' name='city' placeholder='Enter City' value={data.city} onChange={handleValue} /><br />
                <button type='submit'>Register</button> */}
            </form>
        </>
    )
}

export default StaffRegistrationForm
