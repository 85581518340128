import React, { useEffect, useState } from 'react';
import Style from "./MailPreviewPage.module.css"
import { useHistory, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { EditorState, convertFromHTML, ContentState, convertToRaw  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TemplateOne from '../Mail/TemplateOne/TemplateOne'
import TemplateTwo from "../Mail/TemplateTwo/TemplateTwo"
import TemplateThree from "../Mail/TemplateThree/TemplateThree"
import { listLocation } from '../../controllers/location';
import { isAuthenticated } from '../../auth';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { listTag } from '../../controllers/tag';
import Textarea from '@mui/joy/Textarea';
import { get_staff_by_id } from '../../controllers/staff';
import { Input, Result, Spin } from 'antd';
import { Tab, Tabs } from "@mui/material";
import { listCategory } from '../../controllers/category';
import { listAd } from '../../controllers/ad';
import { fetch_setting } from '../../controllers/setting';
import GmailPageOne from '../GmailPage/GmailPageOne/GmailPageOne';
import GmailPageTwo from '../GmailPage/GmailPageTwo/GmailPageTwo';
import TemplateFour from '../Mail/TemplateFour/TemplateFour';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}




const MailPreviewPage = () => {

    const { cityId } = useParams();
    const history = useHistory();
    const { token, name, id } = isAuthenticated();
    const [yes, setYes] = useState('');


    const [permission, setPermission] = useState()
    const fetch_user_data = () => {
        get_staff_by_id(id).then(data => {
            if (data.error) { console.log(data.error); }
            else { setPermission(data.permission) }
        })
    }

    useEffect(() => {
        fetch_user_data()
    }, [])









    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [disableSendButton, setDisableSendButton] = useState(true);
    const [data, setData] = useState([]);
    const [template, setTemplate] = useState("1")
    const [testMail, setTestmail] = useState(null)

    const [editorNote, setEditorNote] = useState(draftToHtml(convertToRaw(editorState.getCurrentContent())))

    const [editCategory, setEditCategory] = useState(false)

    // console.log('template', template)
    // console.log('editorNote', editorNote)



    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    // const city_name = data && data[0]?.category_data[0].event_location.city.city_name.charAt(0).toUpperCase() + data[0]?.category_data[0].event_location.city.city_name.slice(1)
    const [city_name, setCityname] = useState()
    const [newCategoryDescription, setNewCategoryDescription] = useState([]);
    const [locationData, setLocationData] = useState([])
    const [run, setRun] = useState(false)
    const init = async () => {
        try {
            await listLocation(id, token).then(data => {
                setLocationData(data)
            }).catch(error => console.error(error));
            setRun(!run)

            const response = await fetch(`${process.env.REACT_APP_API_URL}/event/send-preview-for-mail?id=${cityId}&template=${template}`, { method: 'GET' });
            const data = await response.json();
            setData(data.event);
        } catch (err) {
            console.log(err);
        }
    };
    // console.log(locationData.filter(item => item._id === cityId));
    useEffect(() => {
        init();
    }, [template]);

    console.log(data);

    useEffect(() => {
        if (locationData.length > 0) {
            const x = locationData.filter(item => item._id === cityId)
            setCityname(x[0].locationName.charAt(0).toUpperCase() + x[0].locationName.slice(1))
        }
    }, [run])


    useEffect(() => {
        if (yes === 'YES') {
            setDisableSendButton(false);
        } else {
            setDisableSendButton(true);
        }
    }, [yes]);

    const [targetAudience, setTragetAudience] = useState([])
    const [tags, setTags] = useState([])
    const [Categorys, setCategorys] = useState([])
    const [selectedTags, setSelectedTags] = useState([]);




    const [title, setTitle] = useState()
    const [subject, setSubject] = useState()
    useEffect(() => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        const newTitle = `${city_name}-Newsletter-${formattedDate}`;
        setTitle(newTitle)
    }, [city_name])
    const initTag = async () => {
        try {
            const tagData = await listTag(id, token);
            setTags(tagData);

            const xyz = { city: [] }
            await listCategory(id, xyz, token).then(data => {
                // console.log(data)
                setCategorys(data)
            }).catch(error => console.error(error));
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        initTag()
    }, [])

    const handleTagChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedTags(selectedValues);
    };

    const tagOptions = tags?.filter(data => data.tagName !== "staff" && data.tagName !== "admin").map((data) => ({
        value: data?._id,
        label: data?.tagName,
    }));

    const templates = [
        { value: "1", label: "Classic" },
        { value: "2", label: "Pop" },
        { value: "3", label: "Tribal" },
        { value: "4", label: "Space" },
    ]

    const TemplateOptions = templates.map((data) => ({
        value: data?.value,
        label: data?.label,
    }))
    const handleTemplateChange = (selectedOptions) => {
        const selectedValues = selectedOptions.value;
        setTemplate(selectedValues)
    }
    // console.log(tagOptions);
    const [saveSuccess,setSaveSuccess] = useState()
    const handleSendMail = async () => {
        if (yes === 'YES') {
            if (!subject || editorNote.length < 9 || !targetAudience) {
                toast.error("All field are required", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/event/mail`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: cityId,
                        template: template,
                        targetaudeince: selectedTags,
                        editorNote: editorNote,
                        title: title,
                        staff_id: id,
                        staff_name: `${name.firstName} ${name.lastName}`,
                        newCategoryDescription: newCategoryDescription,
                        subject: subject
                    })
                });

                const data = await response.json();

                if (data.error) {
                    toast.error(data.error, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

                else {
                    // window.alert(data.message)
                    toast.success(data.message, {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    localStorage.removeItem('categoryDescriptionData');
                    localStorage.removeItem('editorNote');
                    setSaveSuccess("")
                    setYes("")
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        setEditorNote(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }, [draftToHtml(convertToRaw(editorState.getCurrentContent()))])


    const handleSendtestMail = async () => {
        if (testMail === undefined || testMail === "") {
            // window.alert("Please Enter Email to send mail")
            toast.error('Please Enter Valid Email Id', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        if (!subject || editorNote.length < 8) {
            toast.error("All field are required", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        try {
            // const response = await fetch(`${process.env.REACT_APP_API_URL}/event/send-test-mail?cityID=${cityId}&email=${testMail}`, { method: 'GET' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/event/send-test-mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cityID: cityId,
                    email: testMail,
                    template: template,
                    editorNote: editorNote,
                    title: title,
                    newCategoryDescription: newCategoryDescription,
                    subject: subject
                })
            });
            const data = await response.json();
            if (data.error) {
                window.alert(data.error)
            }
            else {
                // window.alert(data.message)
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTestmail("")
            }
        } catch (err) {
            console.log(err);
        }
    }

    // console.log(selectedTags);
    const [value, setValue] = useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };





    const capitalizeSentences = (sentence) => {
        let result = sentence.toLowerCase();
        result = result.replace(/(^|\.\s+)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
        return sentence;
    };



    useEffect(() => {

        Categorys.map((data, index) => {
            const datas = {
                id: data._id,
                CategoryName: data.categoryName,
                description: ""
            }
            setNewCategoryDescription((prevdata) => [...prevdata, datas])
        })
    }, [Categorys])

    const handleCategoryDescription = (event, idx, data) => {
        // console.log(data);
        if(event.target.value.length <= 200){
            const newData = [...newCategoryDescription];
            newData[idx]["id"] = data?._id
            newData[idx]["CategoryName"] = data?.categoryName
            newData[idx]["description"] = capitalizeSentences(event.target.value)
            setNewCategoryDescription(newData)
        }else{
            return
        }
      
    }

    const [successEditorNotesave,setsuccessEditorNotesave] =useState()

    const saveEditorNote = () =>{
        localStorage.setItem('editorNote', editorNote);
        setsuccessEditorNotesave("Editor note saved")
    }

    const saveCategoryDescription = () => {
        localStorage.setItem('categoryDescriptionData', JSON.stringify(newCategoryDescription));
        setSaveSuccess("Category description saved")
    }

    const loadDataFromLocalStorage = () => {
        // Fetch data from local storage
        const savedData = localStorage.getItem('categoryDescriptionData');
        const savedEditorNote = localStorage.getItem('editorNote');
        if (savedData) {
            setNewCategoryDescription(JSON.parse(savedData));
        }
        if(savedEditorNote){
            setEditorNote(savedEditorNote)

            const blocksFromHTML_FooterContent = convertFromHTML(savedEditorNote);
            const contentState_FooterContent = ContentState.createFromBlockArray(blocksFromHTML_FooterContent);
            const initialEditorState_FooterContent = EditorState.createWithContent(contentState_FooterContent);
            setEditorState(initialEditorState_FooterContent);
        }
    };

    // Call this function when the component mounts to load data from local storage
    useEffect(() => {
        loadDataFromLocalStorage();
    }, []);

    const [ads, setAds] = useState([])
    const [footerData, setFooterData] = useState()
    const fetchAds = async () => {
        await listAd(id, cityId, token).then(data => {
            setAds(data)
        }).catch(error => console.error(error));
        await fetch_setting(process.env.REACT_APP_API_SETTING_ID, token).then(data => {
            setFooterData(data)
        })
    }
    useEffect(() => {
        fetchAds()
    }, [])

    const currentDate = new Date()
    console.log(ads[0]);
    console.log(currentDate);
    const previewDataForSidePreview = {
        ad : ads.length > 0 &&  ads?.filter(data => data.ad_images.banner_image !== "" && new Date(data.ad_start_date) <= currentDate && new Date(data.ad_expiry_date) >=currentDate),
        editorNote: editorNote,
        events: data,
        footerData: footerData
    }

    // console.log(previewDataForSidePreview);

    // console.log(newCategoryDescription);



    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true);

        const timeout = setTimeout(() => {
            setLoading(false);
        }, 500);

        return () => clearTimeout(timeout);
    }, [permission])





    return (
        <>
            {loading ? <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin size='large' />

            </div> :
                <>

                    {permission && permission.event.read === 1 && permission.event.create === 1 ?






                        <>

                            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }} >
                                <div className={Style.topSection}>
                                    <h3> <span style={{ color: '#000000', cursor: 'pointer' }} onClick={() => { history.push(`/newsletter`) }} >{city_name}</span> &gt; Event</h3>
                                </div>


                                {data.length > 0 ? "" :
                                    <div>Please Add Event To See The Preveiw</div>
                                }
                                <div className="bg-light flex rounded">

                                </div>
                                <div style={{ display: "flex", gap: '100px' }} className={Style.mailPreviewPage}>


                                    <div style={{ width: "40%" }} className={Style.mailPreviewPage_content}>

                                        <Tabs
                                            value={value}
                                            variant="scrollable"
                                            onChange={handleChange}>
                                            <Tab label="Send" />
                                            <Tab label="Edit Category Description" />
                                        </Tabs>
                                        <TabPanel value={value} index={0}>
                                            <div className={`${Style.mailPreviewPage_contentEditorNotes} ${Style.contentGap}`}>
                                                <p>Newsletter Title</p>
                                                <TextField disabled value={title} onChange={(e) => { setTitle(e.target.value) }} id="outlined-basic-1" placeholder='Enter Newsletter title' variant="outlined" />
                                                <div className={`${Style.mailPreviewPage_contentSelectTemplete} ${Style.contentGap}`} >
                                                <p>Select Template</p>
                                                <Select
                                                    name="template"
                                                    value={TemplateOptions.filter(option => template.includes(option.value))}
                                                    options={TemplateOptions}
                                                    onChange={handleTemplateChange} />
                                            </div>
                                                <p>Newsletter Subject</p>
                                                <TextField value={subject} onChange={(e) => { setSubject(e.target.value) }} id="outlined-basic-1" placeholder='Enter Newsletter Subject' variant="outlined" />
                                                {/* <br /> */}
                                                <p>Editors Notes</p>
                                                <div className="" style={{ minHeight: "200px", borderBottom: '2px solid grey', background: '#F4F4F4' }} >
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={onEditorStateChange}
                                                    />
                                                    <textarea
                                                        // disabled
                                                        value={editorNote}
                                                        // maxlength="500" 
                                                        hidden
                                                    />
                                                </div>
                                            <Button sx={{width:"max-content"}} onClick={saveEditorNote} variant='contained'>Save</Button>
                                            {successEditorNotesave && <span style={{fontSize:"12px",color:"green"}}>{successEditorNotesave}</span>}
                                            
                                                {/* <textarea type="text" maxlength="500" rows={10} cols={60} value={EditorNotes} onChange={(e) => { setEditorsNotes(e.target.value) }} /> */}
                                            </div>
                                            <br />
                                            <p>Target Audience</p>
                                            <Select
                                                placeholder={"Select Target Audience"}
                                                name="tag"
                                                value={tagOptions.filter(option => selectedTags.includes(option.value))}
                                                options={tagOptions}
                                                isMulti
                                                onChange={handleTagChange} />
                                            <br />
                                            <div className={`${Style.mailPreviewPage_contentTestMail} ${Style.contentGap}`}>
                                                <p>Test Mail</p>
                                                <TextField value={testMail} onChange={(e) => { setTestmail(e.target.value) }} id="outlined-basic-1" placeholder='Test mail : random@gmail.com' variant="outlined" />
                                                <Button onClick={handleSendtestMail} className='buttonHover' sx={{ background: '#1976d2', color: 'white', width: '150px' }}>Send</Button>
                                            </div><br />
                                            <div className={`${Style.mailPreviewPage_contentLiveMail} ${Style.contentGap}`}>
                                                <p>Live Mail</p>
                                                <TextField value={yes} id="outlined-basic-1" placeholder='Type "YES" to send mail to all' variant="outlined" onChange={(e) => { setYes(e.target.value); }} />
                                            </div><br />
                                            <Button onClick={handleSendMail} className="buttonHover" disabled={disableSendButton} sx={{ background: '#1976d2', color: 'white', width: '150px' }}>Send to all</Button>
                                            <br /><br />

                                        </TabPanel>








                                        <TabPanel value={value} index={1}>
                                        {/* <p style={{ fontSize: "12px" }}>NOTE :- This descriptions will not be visible in test mail</p> */}
                                            {/* <p style={{ fontSize: "12px" }}>NOTE :- Max character limit - 100</p> */}
                                            {/* <br /> */}
                                            {Categorys.length > 0 && Categorys.map((data, idx) => (

                                                <div key={idx}>
                                                    <p style={{margin:"10px 0 0 0"}}>{data.categoryName}</p>
                                                    <Input.TextArea
                                                        showCount 
                                                        maxLength={200}
                                                        sx={{ width: "100%" }}
                                                        name={data.categoryName}
                                                        value={newCategoryDescription[idx]?.description}
                                                        onChange={(e) => handleCategoryDescription(e, idx, data)}
                                                    />
                                                </div>
                                            ))}
                                            <br />
                                            <Button onClick={saveCategoryDescription} variant='contained'>Save</Button><br/>
                                            {saveSuccess && <span style={{fontSize:"12px",color:"green"}}>{saveSuccess}</span>}
                                            <br />
                                            <br />
                                        </TabPanel>







                                    </div>












                                    <div className="">
                                        <p style={{ fontSize: "30px", fontWeight: "500", marginBottom: '20px' }} >Preview</p>
                                        <div style={{ maxWidth: '550px', height: '750px', overflowY: 'auto' }}>
                                            {template === "1" && footerData && <TemplateOne newCategoryDescription={newCategoryDescription} newsletterData={previewDataForSidePreview} />}
                                            {template === "2" && footerData && <TemplateTwo newCategoryDescription={newCategoryDescription} newsletterData={previewDataForSidePreview} />}
                                            {template === "3" && footerData && <TemplateThree newCategoryDescription={newCategoryDescription} newsletterData={previewDataForSidePreview} />}
                                            {template === "4" && footerData && <TemplateFour newCategoryDescription={newCategoryDescription} newsletterData={previewDataForSidePreview} />}
                                        </div>
                                    </div>












                                </div>

















                            </div>
                        </>
                        :
                        <Result
                            status="403"
                            title="403"
                            subTitle="Sorry, you are not authorized to access this page."
                        />
                    }






                </>}

            <ToastContainer />
        </>
    );
};

export default MailPreviewPage;
