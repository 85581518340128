import React from 'react'
import Style from "../Navbar/Navbar.module.css"
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Avatar from '@mui/material/Avatar';
import { Input } from '@mui/joy';
import { isAuthenticated } from '../../auth';

const Navbar = () => {

    const { name, id, permission, tag } = isAuthenticated();


    return (
        <div className={Style.navBar} >
            <div className={Style.navBarRight}>
                <div className={Style.navBarAccount}>
                    <Avatar sx={{
                        bgcolor: "#1976d2",
                        fontSize: "20px",
                        height: "42px",
                        width: "42px",
                    }} >{name?.firstName[0].toUpperCase()}</Avatar>
                    <div className={Style.navBarAccount_detail}>
                        <p>{name?.firstName.toUpperCase()}{' '}{name?.lastName.toUpperCase()}</p>
                        <span>{tag?.toUpperCase()}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar