import React, { useEffect, useState } from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Style from "../RightSlide/RightSlide.module.css"
import Box from '@mui/material/Box';
import { createLocation, deletLocation, editLocation, listLocation } from '../../../controllers/location'
import { isAuthenticated } from '../../../auth';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@mui/material/IconButton';



export default function RightSlide({ CheckValue,locations, itemId,refersh ,setRefresh }) {

    const { token, id, permission } = isAuthenticated();

    const [location, setlocation] = useState('')
    // const [locations, setlocations] = useState([])
    const [locationId, setLocationId] = useState()
    const [edit, setEdit] = useState(false)
    const [Check, setCheck] = useState(false);

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const handleChange = (e) => {
        setlocation(e.target.value)
    }


    // Toggle Drawer 

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (CheckValue === 2 && open===true) {
            handleEdit(itemId)
        }
      
        setState({ ...state, [anchor]: open });
        console.log('Open')
    };

    const closeDrawer = (anchor, open) => (event) => {
        if (location === "") {
            return
        }
        setState({ ...state, [anchor]: open });
    }



    // Updating Location

    const handleEdit = (id) => {
        setEdit(true);
        const data = locations.filter((data) => data._id === id);
        setLocationId(id);
        setlocation(data[0]?.locationName);
    };
    const [emptyFieldError, setEmptyFieldError] = useState()
    //  for adding Locations
    const handleSubmit = (e) => {
        e.preventDefault()
        if(location === ""){
            setEmptyFieldError("All field are required")
            return
        }
        if (edit === false) {
            const data = {
                locationName: location,
                createdBy:id,
            }
            createLocation(id, data, token)
                .then(data => {
                    if (data.error) {
                        console.log("error : ", data.error);
                    } else {
                        toast.success('Location Added successfully', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                })
        } else {
            const data = {
                locationName: location,
                // createdBy:"",
            }
            // console.log(data);
            editLocation(id, data, locationId, token).then(data => {
                if (data.error) {
                    console.log("error : ", data.error);
                } else {
                    toast.success('Location Updated successfully', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }).catch(error => console.error(error))
        }  setRefresh(!refersh)
        setCheck(!Check)
    }

    // // for listing all the locations
    // const init = async () => {
    //     listLocation(id, token).then(data => {
    //         console.log(data)
    //         setlocations(data)
    //     }).catch(error => console.error(error));

    // };

    // useEffect(() => {
    //     init();
    // }, [Check]);

    // list For Right Slide 

    const list = (anchor) => (
        <Box
            className={Style.rightSlide}
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
        >
            <div className='rightSlide_head'><span onClick={toggleDrawer(anchor, false)} ><IconButton><CloseOutlinedIcon sx={{ color: 'white' }} /></IconButton></span><p>Add Location</p></div>
            <div className={Style.rightSlide_form}>
                <form onSubmit={handleSubmit}>
                    <div className={Style.rightSlide_formInput}>
                        <TextField className={Style.rightSlide_formInput_input} value={location} id="outlined-basic-1" label='Enter Location Name *' variant="outlined" onChange={handleChange} />
                    </div>
                    {emptyFieldError && <p style={{ color: "red", fontSize: "14px" }}>{emptyFieldError}</p>}
                    <div className={Style.rightSlide_formBtn}>
                        <Button type='submit' className='addBtn' variant="contained"
                            onClick={closeDrawer(anchor, false)}  >{CheckValue===1 ? "Add" : "Save"}</Button>
                    </div>
                </form>
            </div>
        </Box>
    );



    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>

                    {CheckValue === 1 ? <><Button onClick={toggleDrawer(anchor, true)} variant="contained"  className='addBtn' >Add Location</Button>
                    </> : CheckValue === 2 ? <><span className='hoverIcon' onClick={toggleDrawer(anchor, true)}   ><IconButton><BorderColorOutlinedIcon /></IconButton></span>
                    </> : ''}

                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
             {/* <ToastContainer /> */}
        </div>
    );
}